import classNames from 'classnames'
import React from 'react'
import Head from 'components/Head'
import Heading from 'components/Heading'
import Layout from 'components/Layout'
import Row from 'components/Row'
import View from 'components/View'
import type { Component } from 'constants/types'
import styles from './Utility.module.scss'
import { ReactComponent as LogoSvg } from './images/logo.svg'

type Props = {
  children: React.ReactNode
  title?: string
} & Component

export default function Utility({ children, className, title }: Props) {
  return (
    <Layout header={false} footer={false} className={classNames('Utility', className)}>
      <Head title={title} />
      <View>
        <Row size="xlarge">
          <LogoSvg aria-label="Bold" className={styles.logo} />
        </Row>
        {title && (
          <Heading align="center" level={1} levelStyle={4}>
            {title}
          </Heading>
        )}
        {children}
      </View>
    </Layout>
  )
}
